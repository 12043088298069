#mapDiv {
  margin-top: 1rem;
  padding-right: 1rem;
  height: 564px;
  width: 564px;
}
#answerMapDiv {
  margin-top: 1rem;
  padding-right: 1rem;
  height: 300px;
  width: 300px;
}
#formMap {
  display: none;
}

.esri-ui-top-right {
  flex-flow: column-reverse;
}
.esri-locate {
  background-color: #84329b;
  color: #fff;
}
.esri-ui-top-right .esri-zoom .esri-widget--button {
  background-color: #84329b;
  color: #fff;
}
.esri-ui-top-right .esri-zoom .esri-widget--button .esri-icon {
  font-size: 18px;
  font-weight: 700;
}
.esri-ui-top-right .esri-locate,
.esri-ui-top-right .esri-zoom .esri-widget--button {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  margin-bottom: 0.75rem;
  box-shadow: none;
}
.esri-ui-top-right .esri-zoom {
  background: 0;
  box-shadow: none;
  border: 0;
}
.esri-search__submit-button {
  display: none;
}
.ds-autocomplete__icon {
  z-index: 9999;
}
.esri-input[type='text'],
.esri-input[type='password'],
.esri-input[type='number'] {
  height: inherit;
}
.esri-search__clear-button {
  display: none;
}
.esri-search__suggestions-list--current-location {
  list-style: none !important;
  -webkit-padding-start: 0 !important;
  padding-inline-start: 0 !important;
}
.esri-input {
  padding: 12px !important;
}
@media only screen and (max-width: 630px) {
  #mapDiv {
    height: 400px;
    width: 400px;
  }
}
@media only screen and (max-width: 430px) {
  #mapDiv {
    height: 300px;
    width: 300px;
  }
}
